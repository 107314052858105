/* CRIDE - CUSTOM COLOR */
$white: #fff;
$dark: #000;
$dark-transparent: #00000052;
$dark-medium: #080B11;
$primary: #38a99a;
$danger: #bd2130;
$gray-light: #b2b2b2;
$gray-text: #707070;
$gray-bg: #f6f6f4;
$bg-table-transparent: rgba(53, 169, 154, 0.04);
$bg-table-hover: rgba(53, 169, 154, 0.1);

/* CRIDE - CUSTOM FONT */
$font-family-sans-serif: 'proxima_nova-regular', sans-serif !default;
$font-family-monospace: SFMono-Regular, Menlo, Monaco, Consolas,
  'Liberation Mono', 'Courier New', monospace !default;
$font-family-base: $font-family-sans-serif !default;
$font-family-montserrat: 'montserrat-regular', sans-serif !default;
$font-family-montserrat-medium: 'montserrat-medium', sans-serif !default;
$font-family-montserrat-semibold: 'montserrat-semibold', sans-serif !default;
$font-family-montserrat-bold: 'montserrat-bold', sans-serif !default;

/* CUSTOM MODAL SIZE x WIDTH */
$modal-sm: 400px;
$modal-lg: 500px;
$modal-xl: 800px;

$bp-tablet: 767px;

// Overriders
$imp: !important;
