.Toastify__toast-container {
  width: 100%;
  top: 0;
  right: 0;
  padding: 0;
}

.Toastify__toast {
  height: 104px;
  text-align: center;
  cursor: auto;
}

.toast-title {
  font-family: 'proxima_nova-regular';
  font-size: 22px;
  font-weight: normal;
}

.toast-message {
  font-family: 'proxima_nova-regular';
  font-size: 16px;
  font-weight: normal;
}
