.delivery-driver-details {
  .profile-img {
    position: relative;
  }
  .profile-load {
    position: absolute;
    top: 45px;
    left: 40px;
    width: 20px;
    height: 20px;
  }
  .profile-name {
    .loader {
      font-size: .7rem;
    }
  }
  .delivery-route {
    padding-left: 35px;
    position: relative;

    &_item {


      &:after {
        content: '....';
        letter-spacing: -1px;
        font-size: 1.9rem;
        position: absolute;
        top: 13px;
        left: 43px;
        transform: rotate(90deg);
      }

      .address {
        color: $gray-text;
      }
    }

    &_item:last-child {

      &:after {
        color: $gray-light;
        content: '..';
        letter-spacing: -1px;
        font-size: 1.9rem;
        position: absolute;
        top: 55px;
        left: 49px;
        transform: rotate(90deg);
      }
    }
  }
  .close {
    right: 25px $imp;
  }
}
