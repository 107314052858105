.drivers-filter {
  position: relative;

  &:after {
    content: " ";
    background-image: url("../../../assets/images/icon-arrow-down.svg");
    background-repeat: no-repeat, repeat;
    background-position: center;
    background-size: 1.5em auto, 100%;
    position: absolute;
    top: 2px;
    left: 0px;
    line-height: 40px;
    width: 20px;
    height: 35px;
  }

  .custom-select-cride {
    color: $dark;
    background-color: $white $imp;
    padding-left: 25px;
    width: 100%;
    appearance: none;
    -webkit-appearance: none;
    -moz-appearance: none;
  }
}
