.delivery-table {
  tbody {
    cursor: pointer;

    .load-td {
      .alert-icon-container {
        width: 20px;
        height: 20px;

        > img {
          width: 100%;
          vertical-align: top;
        }
      }

      &.wrong-load {
        span {
          color: $danger;
        }
      }
    }
  }

  .row-text {
    overflow: hidden;
    white-space: nowrap;
    max-width: 200px;
    text-overflow: ellipsis;
  }

  .client-row-text {
    @extend .row-text;
  }

  .driver-row-text {
    @extend .row-text;
  }

  &__column {
    vertical-align: middle !important;

    .multi-select .dropdown {
      &-container {
        background-color: $primary;
        border: none;

        &:focus-within {
          border: none !important;
          box-shadow: none !important;
        }
      }

      &-heading {
        cursor: pointer;
        padding: 0;

        span {
          font-weight: normal;
          color: $dark;
        }

        svg {
          color: $dark;
        }
      }

      &-content {
        min-width: 180px;
        background-color: $white;
        margin: 0;
        padding: 0;

        .panel-content {
          padding: 10px;
          background-color: $white;

          @include scrollbars(5px, $gray-light, $white);

          div {
            border: none;
            margin-bottom: 5px;
          }

          input {
            background-color: $gray-bg;
            border: none;
            border-radius: 5px;
          }
        }
      }
    }

    &-sort {
      display: flex;
      align-items: center;
      justify-content: space-between;
      white-space: nowrap;
      height: 100%;

      &-buttons {
        display: flex;
        margin-left: 15px;

        button {
          background-color: transparent;
          border: none;
          padding: 0;
          margin: 0;
          margin-left: 10px;
        }
      }
    }

    &-date-range-heading {
      white-space: nowrap;
      display: flex;
      justify-content: space-between;
      align-items: center;
      cursor: pointer;

      svg {
        margin-left: 15px;
      }
    }
  }
}
