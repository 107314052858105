.modal-dialog {
  .modal-content {
  border-radius: 20px;
  box-shadow: 0px 16px 36px $dark-transparent;

    .modal-header {
      border:none;

      .close {
        position: absolute;
        right: 10px;
      }
    }

    .modal-title {
      font-weight: bold;
      text-align: center;
    }
  }
  .loading-indicator {
    padding: 120px;
  }
}
