.delivery-filter {
  width: 500px;
  position: absolute !important;
  z-index: 1000;
  top: 2.5%;
  right: 10%;
  white-space: nowrap;

  label {
    margin-right: 5px;
  }

  .btn-dark:not(:disabled):not(.disabled).active {
    background-color: $primary $imp;
    border-color: $primary;
    color: $dark;
    font-weight: bold;

    &:focus {
      box-shadow: none;
    }
  }
}
