.sidemenu-cride {
  background-color: $dark;
  width: 270px;
  height: 100vh;

  @media screen and ( max-height: 700px ){
    height: inherit;
  }

  &_arrow-back {
    top: 15px;
    left: 25px;
    position: absolute;
    cursor: pointer;
    width: 30px;
    height: 40px;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  &_menu-trigger {
    background-color: transparent;
    position: relative;
    display: inline-block;
    width: 20px;
    height: 30px;

    span {
      position: absolute;
      top: 50%;
      left: 0;
      display: block;
      width: 100%;
      height: 2px;
      background-color: $dark;
      user-select: none;
      transition: background-color 0.2s;

      &:after, &:before {
        position: absolute;
        left: 0;
        width: 100%;
        height: 100%;
        background: $dark;
        content: '';
        transition: transform 0.2s;
      }

      &:before {
        transform: translateY(-4px) translateX(-5px) rotate(-45deg) scaleX(0.6);
      }
      &:after {
        transform: translateY(4px) translateX(-5px) rotate(45deg) scaleX(0.6);
      }
    }

  }

  @at-root .closed#{&} {
    width: 85px;

    .sidemenu-cride {
      &_menu-trigger {
        span {
          &:before {
            transform: translateY(-4px);
          }
          &:after {
            transform: translateY(4px);
          }
        }
      }

      &_logo-svg, &_links-title, &_profile-name, &_links__list-item-link-text {
        display: none;
      }

      &_links__list-item-link-icon {
        margin-right: 0;
      }
    }
  }

  &_logo {
    height: 250px;
    background-color: $primary;
    padding: 40px;
    border-bottom: 5px solid $white;
  }

  &_profile {
    padding: 20px;
    border-bottom: 5px solid $white;

    &-name {
      font-size: 1.2rem;
      line-height: 2rem;
      color: $white;
    }
  }

  &_links {
    color: $white;
    font-weight: 600;
    padding: 40px 0;

    &-title {
      font-size: 1rem;
    }

    &__list {
      list-style: none;
      padding: 0;

      &-item {
        margin: 0;

        &-link {
          $root: &;
          background: transparent;
          color: $white;
          display: block;
          width: 100%;
          padding: 25px 30px;

          &-icon {
            margin-right: 20px;

            path {
              fill: $white;
            }
          }

          &:hover {
            background-color: $primary;
            color: $dark;

            #{$root} {
              &-icon {
                path {
                  fill: $dark;
                }
              }
            }
          }
          .active & {
            @at-root #{&} {
              &-icon {
                path {
                  fill: $dark;
                }
              }
            }
          }
        }
        .active {
          background-color: $primary;
          color: $dark;
          font-weight: bold;
        }
      }
    }
  }
}
