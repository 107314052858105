/* CUSTOM FORMS */
.form-control {
  background-color: $gray-bg $imp;

  @include inputPlaceholder() {
    font-weight: 500;
    color: $gray-light;
  }
}
.custom-control {

  &.custom-checkbox, &.custom-radio {
    .custom-control-label {
      &:before{
        background-color: $gray-bg;
        border: none;
        width: 1.1rem $imp;
        height: 1.1rem $imp;
      }
      &:after {
        width: 1.1rem $imp;
        height: 1.1rem $imp;
      }
    }
  }
  &.custom-switch {
    .custom-control-label {
      &:before{
        background-color: $gray-bg;
        border: none;
      }
    }
  }

}
