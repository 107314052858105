.login-body {
  width: 100vw;
  height: 100vh;
  background: $primary url("../../../assets/images/bg-login.svg") no-repeat center fixed;
  background-size: cover;

  .form-control {
    position: relative;
    z-index: 10;
  }
  .btn.btn-primary:hover{
    background-color: $dark;
  }
  .btn.btn-primary:disabled{
    background-color: $dark;
    cursor: wait;
  }


  .signin-page {

    &-loginheader {
      padding: 0.75rem 1.25rem 0 1.25rem;
    }
    &-logincard {
      padding: 0 1.25rem 1.25rem 1.25rem;
    }
    &-error {
      color: $white;
      background-color: $danger;
      border-radius: 10px;
      padding: 15px 10px 5px 10px;
      margin-top: -10px;
      position: relative;
    }
    &-input-btn-login {
      border-radius: 50px;
      border-color: transparent;
      background-color: $dark;
    }
  }
}
