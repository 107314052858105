// Provima Nova
@font-face {
  font-family: 'proxima_nova-regular';
  src: url('../../fonts/proximanova-regular-webfont.woff2') format('woff2'),
      url('../../fonts/proximanova-regular-webfont.woff') format('woff');
  font-weight: normal;
  font-style: normal;
}
@font-face {
  font-family: 'proxima_nova-semibold';
  src: url('../../fonts/proximanova-semibold-webfont.woff2') format('woff2'),
      url('../../fonts/proximanova-semibold-webfont.woff') format('woff');
  font-weight: 600;
  font-style: normal;
}

// Montserrat
// Provima Nova
@font-face {
  font-family: 'montserrat-regular';
  src: url('../../fonts/montserrat-regular.woff2') format('woff2'),
      url('../../fonts/montserrat-regular.woff') format('woff');
  font-weight: normal;
  font-style: normal;
}
@font-face {
  font-family: 'montserrat-medium';
  src: url('../../fonts/montserrat-medium.woff2') format('woff2'),
      url('../../fonts/montserrat-medium.woff') format('woff');
  font-weight: 500;
  font-style: normal;
}
@font-face {
  font-family: 'montserrat-semibold';
  src: url('../../fonts/montserrat-semibold.woff2') format('woff2'),
      url('../../fonts/montserrat-semibold.woff') format('woff');
  font-weight: 600;
  font-style: normal;
}
@font-face {
  font-family: 'montserrat-bold';
  src: url('../../fonts/montserrat-bold.woff2') format('woff2'),
      url('../../fonts/montserrat-bold.woff') format('woff');
  font-weight: bold;
  font-style: normal;
}
