.popover-filter {
  position: relative;

  &__children {
    position: relative;

    &-counter {
      display: flex;
      justify-content: center;
      align-items: center;

      position: absolute;
      top: -15px;
      right: 20px;

      width: 24px;
      height: 24px;

      border-radius: 50%;
      background-color: $dark;
      color: $white;
    }
  }

  &-list {
    max-height: 350px;
    overflow-y: auto;

    @include scrollbars(5px, $gray-light, $white);
  }
}
