.cride-file-field-container {
  display: grid;
  grid-gap: 10px;
  position: relative;
  z-index: 0;
  width: 142px;
  flex-wrap: nowrap;

  background: $white;



  label {
    position: absolute;
    margin: 0;
    left: 20px;
    right: 10px;
    text-overflow: ellipsis;
    overflow: hidden;
    top: 8px;
    z-index: 10;
    font-family: $font-family-montserrat;
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 130%;
    color: $dark-medium;
  }

  input {
    position: relative;
    z-index: 1;
    padding: 0 gap(m);
    width: 100%;
    height: 35px;
    color: $gray-bg $imp;
    font-size: 1rem;
    border: 2px solid #2C3641;
    border-radius: 80px;
  }

  input[type="file"] {
    padding: 0 gap(m);
  }

  input[type="file"]::-webkit-file-upload-button {
    visibility: hidden;
    margin-left: 10px;
    padding: 0;
    height: 35px;
    width: 0;
  }

}
