.pagination-container {
  background: #000000;
  width: 100%;
  height: 40px;
  display: flex;
  align-items: center;
  justify-content: center;

  .pagination {
    margin-top: initial;
    margin-bottom: initial;
    font-weight: bold;

    .page-link {
      padding: 2px 7px;
      margin-left: 5px;
      line-height: 1.25;
      color: #000000;
      background-color: #ffffff;
      opacity: 0.3;
      border: none !important;
      border-radius: 3px;
    }

    .page-item {
      &.active {
        .page-link {
          background-color: #ffffff;
          color: #000000;
          opacity: 1;
        }
      }
    }

    .arrows .page-link{
      background-color: transparent;
      color: #ffffff;
      opacity: 1;
    }
  }
}
