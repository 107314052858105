.license-validation-text {
  font-size: .95rem;
  color: $gray-light;
}

.drivers-section-title {
  font-family: $font-family-montserrat;
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 130%;
  display: flex;
  align-items: center;
  text-decoration-line: underline;
  color: $dark-medium;
}

.drivers-form-label {
  font-family: $font-family-montserrat;
  font-style: normal;
  font-weight: bold;
  font-size: 14px;
  line-height: 130%;
  color: $dark-medium;
}

.drivers-form-add-picture-button {
  font-family: $font-family-montserrat;
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 130%;
  align-items: center;
  text-align: center;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 7px 23px;
  gap: 8px;
  background: $white;
  border: 2px solid #2C3641;
  border-radius: 80px;
}

.drivers-form-select {
  background: url(http://cdn1.iconfinder.com/data/icons/cc_mono_icon_set/blacks/16x16/br_down.png) no-repeat right #ddd;
  -webkit-appearance: none;
  background-size: 3.5%;
  background-position-x: 95%;
}
