#root {
  display: flex;
}
* {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.admin-content-cride {
  width: 100%;
}

/* REMOVE OUTLINE FROM INPUTS */
// Use border on focus
a:focus,
button:focus,
input:focus,
textarea:focus {
  outline: none $imp;
}

.react-icons {
  margin-top: -7px;
}

/* PREVENT BUG */
// Prevent the bug when modal is open, on side menu and map on all pages
.modal-open {
  overflow-y: scroll $imp;
  padding: 0 $imp;

  .sidemenu-cride {
    padding-right: 0px $imp;
  }
}

/* TABLES */
// Custom cRide Table
.table-cride {

  margin-bottom: 0rem $imp;

  tbody tr:nth-of-type(even) {
    background-color: $bg-table-transparent;
  }

  tbody > tr:hover:not(.selected-table-row){
    background-color: $bg-table-hover $imp;
  }

  .selected-table-row {
    color: $white;
    background-color: $dark $imp;

    .react-icons {
      color: $white;
    }
  }

  thead th {
    background: $primary;
    border-top: none;
    border-bottom: none $imp;
    padding: 0.55rem;
    border-right: 1px solid $white;
  }

  thead th:last-child {
    border-right: none;
  }

  thead th:last-child,
  tr td:last-child {
    padding-right: 15px;
  }

  thead th:first-child,
  tr td:first-child {
    padding-left: 15px $imp;
  }

  td {
    vertical-align: middle $imp;
    border-top: none $imp;
    padding: 0.55rem $imp;
  }

  .load-td {
    min-width: 140px;
  }
  .status-td {
    min-width: 150px;
  }
  .circle-status {
    width: 10px;
    height: 10px;
    border-radius: 20px;
  }
  .react-icons {
    color: $dark;
  }
}
