.gallery {
  img {
    max-height: 588px;
    object-fit: contain;
  }

  .next-prev-icon {
    background-color: $white;
    padding: 20px;
  }

  .carousel-indicators {
    background-color: rgba(0, 0, 0, 0.7);
  }
}
