/// Style the placeholder attribute of input[type="text"] fields.
/// This mixin can be applied globally or on specific text fields
/// @example
/// // all input and textareas
/// @include inputPlaceholder() {
///     color: red;
///     font-weight: 300;
///     padding-top: 5px;
///  }
/// // just textareas
/// textarea {
///     @include inputPlaceholder() {
///         padding: 15px;
///     }
/// }
///
@mixin inputPlaceholder() {
  $selector: '';
  $prefixes: (
    moz: '::-moz',
    webkit: '::-webkit',
    ie: ':-ms',
  );
  @each $prop, $value in $prefixes {
    @if $prop != 'moz' {
      $selector: #{$value}-input-placeholder;
    } @else {
      $selector: #{$value}-placeholder;
    }
    @if & {
      &#{$selector} {
        @content;
      }
    } @else {
      #{$selector} {
        @content;
      }
    }
  }
  &::placeholder {
    @content;
  }
}

@mixin tablet() {
  @media (max-width: #{$bp-tablet}) {
    @content;
  }
}

@mixin scrollbars(
  $size,
  $foreground-color,
  $background-color: mix($foreground-color, white, 50%)
) {
  // For Google Chrome
  &::-webkit-scrollbar {
    width: $size;
  }

  &::-webkit-scrollbar-thumb {
    background: $foreground-color;
    border-radius: $size / 2;
  }

  &::-webkit-scrollbar-track {
    background: $background-color;
  }

  // For Internet Explorer
  & {
    scrollbar-face-color: $foreground-color;
    scrollbar-track-color: $background-color;
  }
}
