.delivery-modal-photos {
  .photo-field {
    position: relative;
    height: 85px;
    width: 85px;
    margin-bottom: 5px;

    &:nth-child(2n + 1) {
      margin-right: 5px;
      background-color: brown;
    }

    &_file {
      &:-webkit-file-upload-button {
        visibility: hidden;
      }
    }
    &_delete {
      font-size: 1.4rem;
      left: -8px;
      top: -8px;
      position: absolute;
    }
    &_img {
      height: 85px;
      width: 85px;
    }
    &_button {
      padding: 0;
      border: none;
    }
  }

  &_upload-wrapper {
    position: relative;
    height: 85px;
    width: 85px;
    background-color: $gray-bg;
    cursor: pointer;

    input[type='file'] {
      font-size: 100px;
      height: 85px;
      width: 85px;
      position: absolute;
      left: 0;
      top: 0;
      opacity: 0;
    }

    &-icon {
      font-size: 1.6rem;
    }
  }
}
.delivery-modal-photos_footer {
  .btn-cancel {
    width: 46%;
  }
  .btn-submit {
    width: 48%;
  }
}
.select-delivery-status-color {
  background-color: $white !important;
  border-color: $gray-light;
  border-radius: 6px;
  height: 35px;
  width: 100%;
}

.delivery-form {
  &-field {
    &-fake {
      overflow: hidden;

      p {
        white-space: nowrap;
        text-overflow: ellipsis;
        max-width: 320px;
        overflow: hidden;
        padding: 8px 0 0 0;
      }
    }

    &-disabled {
      cursor: not-allowed;
    }
  }

  &-button {
    width: 50%;

    @include tablet {
      width: 100%;
      margin-bottom: 10px;
    }
  }

  .small-error-message {
    color: $danger;
    display: block;
    max-width: 80%;
  }
}
