.table-cride {
  &-clients {
    &-notification-row {
      display: flex;
      justify-content: space-between;
      align-items: center;

      > .label {
        display: flex;
        align-items: center;

        > .circle {
          width: 10px;
          height: 10px;
          border-radius: 5px;
          background-color: red;
          margin-right: 3px;

          &.on {
            background-color: $primary;
          }

          &.off {
            background-color: $gray-light;
          }
        }
      }
    }
  }
}
