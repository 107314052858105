.react-datepicker-wrapper{
  display: block;
}

.badge-box{
  margin-left: 180px;
}

.multi-select {
  --rmsc-bg: #F6F6F4 !important;
}
