.loading {

  &-container {
    height: 30px;
  }

  &-indicator {
    font-size: 1rem;

    &-spinner {
      margin-right: 5px;
    }
  }
}

