.fake-field {
  overflow: hidden;

  p {
    white-space: nowrap;
    text-overflow: ellipsis;
    max-width: 300px;
    overflow: hidden;
    padding: 8px 0 0 0;
  }
}
