.cride-datepicker {
  background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16'%3E%3Cg opacity='.4'%3E%3Cpath d='M13.3 16H2.7C1.2 16 0 14.8 0 13.3V3.9c0-1.5 1.2-2.7 2.7-2.7h10.5c1.5 0 2.7 1.2 2.7 2.7v9.4c.1 1.5-1.1 2.7-2.6 2.7zM2.7 2.4c-.8 0-1.5.7-1.5 1.5v9.4c0 .8.7 1.5 1.5 1.5h10.5c.8 0 1.5-.7 1.5-1.5V3.9c0-.8-.7-1.5-1.5-1.5H2.7z'/%3E%3Ccircle cx='8.8' cy='6.5' r='.9' transform='translate(.6 .6)'/%3E%3Ccircle cx='11.7' cy='6.5' r='.9' transform='translate(.6 .6)'/%3E%3Ccircle cx='8.8' cy='9.4' r='.9' transform='translate(.6 .6)'/%3E%3Ccircle cx='11.7' cy='9.4' r='.9' transform='translate(.6 .6)'/%3E%3Ccircle cx='3.1' cy='9.4' r='.9' transform='translate(.6 .6)'/%3E%3Ccircle cx='6' cy='9.4' r='.9' transform='translate(.6 .6)'/%3E%3Ccircle cx='3.1' cy='12.2' r='.9' transform='translate(.6 .6)'/%3E%3Ccircle cx='6' cy='12.2' r='.9' transform='translate(.6 .6)'/%3E%3Ccircle cx='8.8' cy='12.2' r='.9' transform='translate(.6 .6)'/%3E%3Cpath d='M12.5 2.4c-.3 0-.6-.3-.6-.6V.6c0-.3.3-.6.6-.6s.6.3.6.6v1.1c0 .4-.2.7-.6.7zm-9 0c-.3 0-.6-.3-.6-.6V.6c0-.3.3-.6.6-.6s.6.3.6.6v1.1c0 .4-.3.7-.6.7zM.6 4h14.8v1.2H.6z'/%3E%3C/g%3E%3C/svg%3E");
  background-position-x: 95%;
  background-position-y: center;
  background-repeat: no-repeat;
  background-size: 18px;
  padding-right: 28px $imp;
  border: none;
  display: none;

  text-transform: lowercase;

  &-popper {
    .react-datepicker {
      display: flex;
    }
  }
}
